import React from 'react'
import GridItem from './components/GridItem'
import ReactGA from 'react-ga'

import ios_icon_web_bw from './img/ios_icon_web_bw.png'
import holme_logo_bw from './img/holme_logo_bw.png'
import linkedin from './img/linkedin.png'
import sveiseblind from './img/sveiseblind.png'
import gbExtension from './img/gb-extension.png'
import stravaKudos from './img/strava-kudos.png'
import {
  ChakraProvider,
  Text,
  extendTheme,
  Wrap,
  Container,
  Stack,
} from '@chakra-ui/react'

import { Fonts } from './Fonts'

const theme = extendTheme({
  fonts: {
    heading: 'Open Sans',
    body: 'Raleway',
  },
})

const trackingId = 'UA-170967090-1'
ReactGA.initialize(trackingId, {
  debug: true,
})

function App() {
  ReactGA.pageview('/')

  return (
    <ChakraProvider theme={theme}>
      <div id='sketch-holder'></div>
      <Fonts />
      <Container centerContent>
        <Stack>
          <Text fontSize='50px' zIndex={2} align='center'>
            Jonathan Aanesen
          </Text>
          <Wrap spacing='60px' justify='center' p='5' centerContent>
            <GridItem
              img={ios_icon_web_bw}
              alt='logbook web button'
              link='https://loggbok.net/'
              name='Skydiving Logbook Web'
            />
            <GridItem
              img={holme_logo_bw}
              alt='Holme Design button'
              link='https://holmedesign.no'
              name='Holme Design'
            />
            <GridItem
              img={linkedin}
              alt='linkedin button'
              link='https://www.linkedin.com/in/jaanesen/'
              name='Linkedin'
            />
            <GridItem
              img={sveiseblind}
              alt='sveiseblind button'
              link='https://sveiseblind-48460.web.app/'
              name='Sveiseblind'
            />
            <GridItem
              img={gbExtension}
              alt='gb-extension'
              link='https://chromewebstore.google.com/detail/obnbbhphaglnanbhplfenedbdkoecamm?hl=en&authuser=0'
              name='Golfbox Extension'
            />
            <GridItem
              img={stravaKudos}
              alt='strava-clicker'
              link='https://chromewebstore.google.com/detail/strava-kudos-clicker/alombobanogepkgdciekfiahakhjkmjn'
              name='Strava Kudos Clicker Extension'
            />
          </Wrap>
        </Stack>
      </Container>
    </ChakraProvider>
  )
}

export default App
